import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import CarouselItem from '../carousel/carousel-item'

import image from '../../images/the-lancashire-hotpots-bisto-inferno-tour-2024.jpg'

const newsUrl = '/news/2023-10-26/bisto-inferno-tour-additional-2024-dates/'

const BistoInfernoTourCarouselItem = ({ active }) => (
  <CarouselItem
    img={image}
    imgAlt={`A promotional image of the Lancashire Hotpots for their 2024 Bisto Inferno tour dates`}
    active={active}
  >
    <Link to={newsUrl} className='btn btn-danger btn-sm btn-block d-md-none'>
      Additions Bisto Inferno Dates On Sale Now
    </Link>
    <Link to={newsUrl} className='btn btn-danger btn-block d-none d-md-block'>
      Additional Bisto Inferno Tour Dates On Sale Now - click here to view all dates!
    </Link>
  </CarouselItem>
)

BistoInfernoTourCarouselItem.propTypes = {
  active: PropTypes.bool,
}

BistoInfernoTourCarouselItem.defaultProps = {
  active: false,
}

export default BistoInfernoTourCarouselItem

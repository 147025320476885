import React from 'react'
import PropTypes from 'prop-types'

const CarouselIndicators = ({ carouselId, numberOfIndicators }) => {
  const target = '#' + carouselId

  return (
    <ol className='carousel-indicators'>
      {generateIndicators(numberOfIndicators, target)}
    </ol>
  )
}

CarouselIndicators.propTypes = {
  carouselId: PropTypes.string.isRequired,
  numberOfIndicators: PropTypes.number,
}

export default CarouselIndicators

const generateIndicators = (numberOfIndicators, target) => {
  const indicators = []

  for (let i = 0; i < numberOfIndicators; i++) {
    indicators.push(
      <li
        data-target={target}
        data-slide-to={i}
        className={i === 0 ? 'active' : null}
        key={i}
      ></li>,
    )
  }

  return indicators
}

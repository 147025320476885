import React from 'react'
import PropTypes from 'prop-types'

import CarouselItemContainer from './carousel-item-container'
import CarouselItemHeader from './carousel-item-header'
import CarouselItemImage from './carousel-item-image'

const CarouselItem = ({ active, children, img, imgAlt, header }) => (
  <CarouselItemContainer active={active}>
    <CarouselItemImage src={img} alt={imgAlt} />
    <div className='carousel-caption'>
      {displayHeader(header)}
      {children}
    </div>
  </CarouselItemContainer>
)

const displayHeader = (header) => {
  if (header) return <CarouselItemHeader>{header}</CarouselItemHeader>
}

CarouselItem.propTypes = {
  active: PropTypes.bool,
  img: PropTypes.string.isRequired,
  imgAlt: PropTypes.string,
  header: PropTypes.string,
}

CarouselItem.defaultProps = {
  active: false,
  imgAlt: 'The Lancashire Hotpots',
}

export default CarouselItem

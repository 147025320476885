import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const FacebookPagePlugin = (props) => {
  useEffect(() => {
    if (window.FB) {
      window.FB.XFBML.parse()
    }
  })

  return (
    <div
      className='fb-page'
      data-href={props.href}
      data-tabs={props.tabs}
      data-height={props.height}
      data-width={props.width}
      data-small-header={getBoolValue(props.smallHeader)}
      data-adapt-container-width={getBoolValue(props.adaptContainerWidth)}
      data-hide-cover={getBoolValue(props.hideCover)}
      data-show-facepile={getBoolValue(props.showFacepile)}
    >
      <blockquote cite={props.href} className='fb-xfbml-parse-ignore'>
        <a href={props.href}>{props.name}</a>
      </blockquote>
    </div>
  )
}

FacebookPagePlugin.propTypes = {
  adaptContainerWidth: PropTypes.bool,
  height: PropTypes.number,
  hideCover: PropTypes.bool,
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showFacepile: PropTypes.bool,
  smallHeader: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.oneOf(['events', 'messages', 'timeline'])),
  width: PropTypes.number,
}

FacebookPagePlugin.defaultProps = {
  adaptContainerWidth: true,
  hideCover: false,
  height: null,
  showFacepile: true,
  smallHeader: false,
  tabs: [],
  width: null,
}

export default FacebookPagePlugin

const getBoolValue = (prop) => (prop === true ? 'true' : 'false')

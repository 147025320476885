import React from 'react'
import PropTypes from 'prop-types'

import CarouselControls from './carousel-controls'
import CarouselIndicators from './carousel-indicators'
import CarouselItem from './carousel-item'

const Carousel = ({ children, fade, id }) => {
  const classes = ['carousel', 'slide']

  if (fade === true) classes.push('carousel-fade')

  return (
    <div id={id} className={classes.join(' ')} data-ride='carousel'>
      <CarouselIndicators
        carouselId={id}
        numberOfIndicators={children.length}
      />
      <div className='carousel-inner'>{children}</div>
      <CarouselControls carouselId={id} />
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.arrayOf(CarouselItem),
  fade: PropTypes.bool,
  id: PropTypes.string.isRequired,
}

Carousel.defaultProps = {
  fade: false,
}

export default Carousel

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import CarouselItem from '../carousel/carousel-item'

import image from '../../images/the-lancashire-hotpots-big-bash-4-manchester.jpg'

const BigBash4CarouselItem = ({ active }) => (
  <CarouselItem
    img={image}
    imgAlt={`Big Bash 4 promotional image featuring The Lancashire Hotpots, Showaddywaddy and Black Lace`}
    active={active}
  >
    <Link
      to='/news/2024-04-22/big-bash-4-early-bird-tickets-on-sale-now/'
      className='btn btn-danger btn-sm btn-block d-md-none'
    >
      Big Bash 4 Early Bird Tickets On Sale Now!
    </Link>
    <Link
      to='/news/2024-04-22/big-bash-4-early-bird-tickets-on-sale-now/'
      className='btn btn-danger btn-block d-none d-md-block'
    >
      Big Bash 4 Early Bird Tickets On Sale Now!
    </Link>
  </CarouselItem>
)

BigBash4CarouselItem.propTypes = {
  active: PropTypes.bool,
}

BigBash4CarouselItem.defaultProps = {
  active: false,
}

export default BigBash4CarouselItem

import React from 'react'
import PropTypes from 'prop-types'

const CarouselItemHeader = ({ children }) => (
  <h4 className='text-warning'>{children}</h4>
)

CarouselItemHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default CarouselItemHeader

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import CarouselItem from '../carousel/carousel-item'

import image from '../../images/the-lancashire-hotpots-non-stop-saturday-night-tour.jpg'

const newsUrl = '/news/2023-11-24/non-stop-saturday-night-tour-on-sale/'

const NonStopSaturdayNightTourCarouselItem = ({ active }) => (
  <CarouselItem
    img={image}
    imgAlt={`A promotional image of the Lancashire Hotpots for their 2024 Non Stop Saturday Night tour dates`}
    active={active}
  >
    <Link to={newsUrl} className='btn btn-danger btn-sm btn-block d-md-none'>
      Non Stop Saturday Night Tour On Sale Now
    </Link>
    <Link to={newsUrl} className='btn btn-danger btn-block d-none d-md-block'>
      Non Stop Saturday Night Tour On Sale Now - click here to view all dates!
    </Link>
  </CarouselItem>
)

NonStopSaturdayNightTourCarouselItem.propTypes = {
  active: PropTypes.bool,
}

NonStopSaturdayNightTourCarouselItem.defaultProps = {
  active: false,
}

export default NonStopSaturdayNightTourCarouselItem

import React from 'react'
// import PropTypes from 'prop-types'

const CarouselItemContainer = ({ active, children }) => {
  return (
    <div className={`carousel-item ${active === true ? 'active' : ''}`}>
      {children}
    </div>
  )
}

export default CarouselItemContainer

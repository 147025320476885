import React from 'react'
import PropTypes from 'prop-types'

const CarouselItemImage = ({ alt, src }) => (
  <img src={src} className='d-block w-100' alt={alt} />
)

CarouselItemImage.propTypes = {
  alt: PropTypes.string,
}

export default CarouselItemImage

import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout/layout'

import Breadcrumb from '../components/breadcrumb/breadcrumb'
import Carousel from '../components/carousel/carousel'
import EventsTable from '../components/events/events-table'
import FacebookPagePlugin from '../components/facebook/page-plugin'
import SEO from '../components/seo'
import TwitterTimeline from '../components/twitter/timeline'

import BigBash4CarouselItem from '../components/carousel-items/big-bash-4'
import BistoInfernoTour2024CarouselItem from '../components/carousel-items/bisto-inferno-tour-2024'
import NonStopSaturdayNightTourCarouselItem from '../components/carousel-items/non-stop-saturday-night-tour'
import EggSausageChipsBeansRemixCarouselItem from '../components/carousel-items/egg-sausage-chips-beans-remix'

const IndexPage = ({ data }) => {
  const events = data.allMarkdownRemark.edges

  return (
    <>
      <SEO />

      <Layout>
        <Breadcrumb />

        <div className='row'>
          <div className='col mb-4'>
            <h4 className='bg-danger text-light p-2'>News</h4>

            <Carousel id='homepageCarousel' fade>
              <BigBash4CarouselItem active />
              <NonStopSaturdayNightTourCarouselItem />
              <BistoInfernoTour2024CarouselItem />
              <EggSausageChipsBeansRemixCarouselItem />
            </Carousel>

            <Link to='/news/' className='btn btn-link btn-block mt-2'>
              All news
            </Link>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-lg-4 mb-2'>
            <h4 className='bg-danger text-light p-2'>Events</h4>

            <EventsTable events={events} type='upcoming' limit={5} condensed />

            <Link to='/events/' className='btn btn-link btn-block mt-2'>
              All events
            </Link>
          </div>
          <div className='col-12 col-md-6 col-lg-4 mb-2'>
            <h4 className='bg-danger text-light p-2'>Twitter</h4>
            <TwitterTimeline handle='thehotpots' limit={1} noheader nofooter />
            <a
              href='https://twitter.com/thehotpots'
              className='btn btn-link btn-block mt-2'
            >
              View on Twitter
            </a>
          </div>
          <div className='col-12 col-md-6 col-lg-4 mb-2'>
            <h4 className='bg-danger text-light p-2'>Facebook</h4>
            <FacebookPagePlugin
              href='https://www.facebook.com/thelancashirehotpots/'
              name='The Lancashire Hotpots'
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "events" } } }
      sort: { fields: frontmatter___datetime, order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            datetime
            status
            ticketURL
            type
            tour {
              frontmatter {
                tourId
              }
            }
            venue {
              frontmatter {
                venueId
                displayName
                address {
                  street
                  locality
                  region
                  postcode
                }
                email
                telephone
                websiteURL
              }
            }
          }
        }
      }
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'

const CarouselControls = ({ carouselId }) => {
  const href = '#' + carouselId

  return (
    <>
      <a
        className='carousel-control-prev'
        href={href}
        role='button'
        data-slide='prev'
      >
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='sr-only'>Previous</span>
      </a>
      <a
        className='carousel-control-next'
        href={href}
        role='button'
        data-slide='next'
      >
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='sr-only'>Next</span>
      </a>
    </>
  )
}

CarouselControls.propTypes = {
  carouselId: PropTypes.string.isRequired,
}

export default CarouselControls

import React from 'react'
import PropTypes from 'prop-types'

const TwitterTimeline = (props) => {
  const { handle, limit } = props
  const href = `https://twitter.com/${handle}`

  return (
    <a
      className='twitter-timeline'
      href={href}
      data-chrome={getDataChromeTokens(props)}
      data-tweet-limit={limit}
    >
      Tweets by {handle}
    </a>
  )
}

function getDataChromeTokens(props) {
  const availableTokens = [
    'noborders',
    'nofooter',
    'noheader',
    'noscrollbar',
    'transparent',
  ]

  return availableTokens.filter((token) => props[token] === true).join(' ')
}

TwitterTimeline.propTypes = {
  handle: PropTypes.string.isRequired,
  limit: PropTypes.number,
  noborders: PropTypes.bool,
  nofooter: PropTypes.bool,
  noheader: PropTypes.bool,
  noscrollbar: PropTypes.bool,
  transparent: PropTypes.bool,
}

export default TwitterTimeline

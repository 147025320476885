import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import CarouselItem from '../carousel/carousel-item'

import image from '../../images/the-lancashire-hotpots-egg-sausage-chips-beans-remix-carousel.jpg'

const EggSausageChipsBeansRemixCarouselItem = ({ active }) => (
  <CarouselItem
    img={image}
    imgAlt={`The Lancashire Hotpots Egg Sausage Chips and Beans Ricardo Autobahn Remix`}
    active={active}
  >
    <Link
      to='/news/2020-06-26/egg-sausage-chips-beans-2020-remix-out-now/'
      className='btn btn-danger btn-sm btn-block d-md-none'
    >
      Ricardo Autobahn Remix - Out Now!
    </Link>
    <Link
      to='/news/2020-06-26/egg-sausage-chips-beans-2020-remix-out-now/'
      className='btn btn-danger btn-block d-none d-md-block'
    >
      Egg Sausage Chips and Beans Ricardo Autobahn Remix - Out Now!
    </Link>
  </CarouselItem>
)

EggSausageChipsBeansRemixCarouselItem.propTypes = {
  active: PropTypes.bool,
}

EggSausageChipsBeansRemixCarouselItem.defaultProps = {
  active: false,
}

export default EggSausageChipsBeansRemixCarouselItem
